import  request  from "@/utils/request"
// 账号登录
export function loginByPassword (parameter){
    return request({
       url:'/login',
       method:'post',
       data:parameter
   })
}

//注册
export function register (parameter){
    return request({
       url:'/api/user/register',
       method:'post',
       data:parameter
   })
}
// 获取验证码
export function getSms (parameter){
    return request({
       url:'/api/captcha/sms/login',
       method:'post',
       data:parameter
   })
}

//验证码登录
export function loginByCode (parameter){
    return request({
       url:'/api/user/mobile',
       method:'post',
       data:parameter
   })
}

//tui登录
export function logOut (parameter){
    return request({
       url:'/api/user/logout',
       method:'post',
       data:parameter
   })
}
