
import axios from 'axios'
import router from '../router/index'
import store from '../store/index'
export default function request(config) {
    const instance = axios.create({
       // baseURL:'http://api.drbodyhealth.com',
        timeout:10000000,
        headers:{
            Authorization:localStorage.getItem('token')
        }
    });

//请求拦截 ，将token带入请求头中
instance.interceptors.request.use((config)=> {
    //console.log("denglu",config);

    config.cancelToken = new axios.CancelToken(cancel => {
        store.commit('addCancelToken', cancel)
      })

    if(config.url=='/login'){
        return config
    }
    else{
        let token = localStorage.getItem('token');
        if (token) { 
           config.headers['Authorization'] =token
        }
        return config
    }
     
  }, 
  (error) =>{
    return Promise.reject(error)
});

//响应拦截
instance.interceptors.response.use(
    response=>{ 
        // http 200状态返回
       //console.log("拦截状态",response.data);
       var res = response.data;
       if(res.status == 401){
             router.replace({
                path: '/login' 
            })
       }
       if(res.status == 403){
            //alert(res.message);
           // console.log("权限不足")
        }
       //进行其它拦截
        return response
    }, 
    error=>{ 
        
        /* // 后台抛出异常状态拦截
        const res = error.response.data;
        if(res.status == 401){
            // alert(res.message);
              router.replace({
                 path: '/login' 
             })
        }
        if(res.status == 403){
             //alert(res.message);
             router.replace({
                 path: '/login' 
             })
         }
        console.log("拦截异常",error.response.data);
        */
        return Promise.reject(error)
    }
)

//最后返回实列
    return instance(config)
}




