<template>
  <div class="common">
    <!-- top -->
   <div class="top">
     <img alt="logo" class="logo" :src="logo">
     <div class="user">
       <i class="el-icon-search" style="font-size:16px;margin-right:16px"></i>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-message-solid" style="font-size:16px;margin-right:40px"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="clearfix">评论<el-badge class="mark" :value="12" /></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 用户名称 -->
        <div class="userName">{{userName}}</div>
        <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" style="width:40px;height:40px"></el-avatar>
        <!-- 下拉退出 -->
        <el-dropdown @command="loginCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down el-icon--right" style="font-size:26px"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="acount">账号信息</el-dropdown-item> -->
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
     </div>
   </div>
   <div class="content">
      <!-- left -->
      <div class="left">
          <el-menu  unique-opened :collapse-transition="false"  router active-text-color="#409EFF" text-color="#353535" :default-active="activeIndex" @select="handleSelect">
            <el-submenu  v-for="(item,index) in menuList" :key="index" :index="item.id+ ' '" >
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span slot="title">{{item.name}}</span>
                </template>
                <el-menu-item v-for="(chr_item) in item.children" :key="chr_item.id" :index="chr_item.path">{{chr_item.name}}</el-menu-item>
            </el-submenu>        
            <!-- 
              <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-monitor"></i>
                <span slot="title">首页</span>
             </template>
              <el-menu-item index="/home">数据可视化</el-menu-item>
            </el-submenu>

            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-data-line"></i>
                <span slot="title">受检管理</span>
              </template>
                <el-menu-item index="/userList">受检档案列表</el-menu-item>
                <el-menu-item index="/spineEvaluation">受检评估</el-menu-item>
                <el-menu-item index="/checkTask">检测任务</el-menu-item>
            </el-submenu>

            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-user"></i>
                <span slot="title">系统管理</span>
              </template>
              <el-menu-item index="/wxUser">wx账号管理</el-menu-item>
               <el-menu-item index="/webUser">web账号管理</el-menu-item>
              <el-menu-item index="/orgController">机构管理</el-menu-item>
              <el-menu-item index="/role">角色管理</el-menu-item>
              <el-menu-item index="/menu">菜单管理</el-menu-item>
            </el-submenu>
             <el-submenu index="4">
              <template slot="title">
                <i class="el-icon-notebook-1"></i>
                <span slot="title">花名册管理</span>
              </template>
                <el-menu-item index="/roster">花名册列表</el-menu-item>
                <el-menu-item index="/uploadRoster">上传花名册</el-menu-item>
            </el-submenu>

            <el-submenu index="5">
              <template slot="title">
                <i class="el-icon-upload"></i>
                <span slot="title">文件管理</span>
              </template>
                <el-menu-item index="/uploadfiles">批量上传文件</el-menu-item>
                <el-menu-item index="/processFileList">报告处理列表</el-menu-item>
            </el-submenu>  

            <el-submenu index="6">
              <template slot="title">
                <i class="el-icon-folder-checked"></i>
                <span slot="title">预约管理</span>
              </template>
                <el-menu-item index="/recover">预约康复</el-menu-item>
                <el-menu-item index="/review">预约复查</el-menu-item>
            </el-submenu>    

             <el-submenu index="7">
              <template slot="title">
                <i class="el-icon-date"></i>
                <span slot="title">排班管理</span>
              </template>
                <el-menu-item index="/scheduRules">排班规则</el-menu-item>
                <el-menu-item index="/scheduCondition">排班情况</el-menu-item>
            </el-submenu>   

            <el-submenu index="8">
              <template slot="title">
                <i class="el-icon-postcard"></i>
                <span slot="title">机构信息</span>
              </template>
                <el-menu-item index="/baseInfo">基本信息</el-menu-item>
                <el-menu-item index="/department">科室管理</el-menu-item>
            </el-submenu>  
            
            <el-submenu index="9">
              <template slot="title">
                <i class="el-icon-set-up"></i>
                <span slot="title">专家管理</span>
              </template>
                <el-menu-item index="/expertList">专家列表</el-menu-item>
                <el-menu-item index="/applyList">申请列表</el-menu-item>
            </el-submenu>   -->

          </el-menu>
      </div>
      <!-- cotent -->
      <div class="right">
        <div class="childRoute" style="padding:24px">
            <router-view></router-view>
        </div>
      </div>
   </div>
   <!-- 账号信息弹框 -->
   <el-dialog :visible.sync="showAcount" custom-class="dialog">
      <div class="conten-box">
        <div class="cb-title"><div class="blue-box"></div><div class="cb-name">账号信息</div></div>
        <div class="info-name">
          <span class="info-bold" style="margin-top: 60px;">姓名</span>
          <span class="info-normal">李飞</span>
        </div>
        <div class="info-name">
          <span class="info-bold">所属科室</span>
          <span class="info-normal">康复科</span>
        </div>
        <div class="info-name">
          <span class="info-bold">账号</span>
          <span class="info-normal">138299038767</span>
        </div>
        <!-- 修改密码 -->
        <div class="info-name">
          <span class="info-bold">修改密码</span>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0px" style="margin-top:10px;">
            <el-form-item prop="pass">
              <el-input type="password" style="width:326px;" v-model="ruleForm.pass" autocomplete="off" placeholder="请输入原密码"></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
              <el-input type="password" style="width:326px;" v-model="ruleForm.checkPass" autocomplete="off" placeholder="请输入新密码"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 保存/取消 -->
        <div class="submit">
          <div class="sb-save">保存</div>
          <div class="sb-cancel">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getMenuList} from '@/api/userRoot.js'
import {logOut} from '@/api/login.js'


export default {
 
  data(){
    var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
    var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return{
      activeIndex: '/home',//默认激活选中
      userName:null,
      menuList:null,
      //logo:require('assets/img/logo.png'),
      logo:'https://sz.drbodyhealth.com/logo.png',
      showAcount:false,
      ruleForm: {
          pass: '',
          checkPass: '',
        },
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ],
        }

    }
  },

  
  created() {
    this.userName = null;
    this.menuList = null;
    this.activeIndex = sessionStorage.getItem('keyPath') || '/home';
    const token = localStorage.getItem('token');
    if(token){
       this.getMenuData();
      }
    const userName = localStorage.getItem('userName');
    if(userName){
      this.userName = userName;
    }
  },

  watch:{
          activeIndex(newValue){
            if(newValue == this.$route.path){
                this.activeIndex = this.$route.path;
            }
          }
        },
  methods:{
    loginCommand(e){
      if(e=='acount'){
        this.showAcount = true;
      }
      if(e=='logout'){
          //退出登录
        logOut({}).then(res=>{
          if(res.data.status == 200){
              this.$router.push({name:'login'});
              localStorage.clear();
              sessionStorage.clear();
              location.reload()
          }else{
            this.$message.error(res.data.message);
          }
        }).catch(err=>{
          this.$message.error(err);
        })
      }
    },

    //获取菜单列表
    getMenuData(){
      getMenuList().then(res=>{
        if(res.data.status==200){
          this.menuList = res.data.data;
        }else{
          this.$message.error(res.data.message);
        }
      }).catch(err=>{
        this.$message.error(err);
      })
    },
    //子菜单激活状态
    handleSelect(keyPath) {
        sessionStorage.setItem('keyPath', keyPath);  
      }

  },

}
</script>
<style scoped lang="stylus">
/deep/element.style {
 display: flex;
 align-items: center !important;
}
/deep/.el-menu-item.is-active {
  color: #ffffff !important;
  background-color: #00cccc !important
}
/deep/.el-menu-item.is-hover{
  background-color: #CCF5F5 !important;
  color: #353535 !important;

}
/deep/.el-dialog__body{
 padding: 0 20px 40px 20px !important;
}

/deep/.el-tabs__item.is-active {
  color: #fff !important;
  background-color: #00cccc !important;
}

/deep/.el-tabs__item.is-hover {
  color: #303133 !important;
}

// conpomentClass 
.common
  .top 
    width: 100%
    height: 80px
    margin-top: 24px
    background-color: #FFFFFF
    display: flex
    align-items: center
    justify-content: space-between
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
    .logo
      width: 147px
      height: 24px
      margin-left: 47px
    .user 
      height: 40px
      display: flex
      align-items: center
      margin-right: 64px
      .userName
        font-family: Source Han Sans CN
        font-size: 14px
        line-height: 24px
        color: #353535
        margin-right: 16px
  .content 
    width: 100%
    overflow hidden
    padding: 0 !important
    display: flex
    background-color: rgba(246, 247, 249, 0.39);
    .left 
      width: 230px
      height: auto
      min-height: 100vh
      background-color: #fff
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
      margin-top: 4px
    .right
      width: 100%
      background-color: #F6F7F9

.dialog
  width: 800px
  .conten-box
    width: 350px
    overflow hidden
    margin: 0 auto 
    position: relative
    .cb-title
      position: absolute
      display: flex
      left: 50%
      transform: translateX(-50%)
      align-items: center
      .blue-box
        width: 3px;
        height: 22px;
        border-radius: 3px;
        margin-right: 6px;
        background-color: #00cccc;
      .cb-name
        font-size: 18px;
        font-weight: bold;
        color: #353535;
    .info-name
      margin-top: 30px;
      .info-bold
        display: block
        font-size: 16px   
        font-weight: bold;
        color:#323233;
      .info-normal
        display: block
        font-size: 16px   
        color:#666666;
        margin-top: 10px;
    .submit
      display: flex;
      margin: 40px auto 0 auto;
      .sb-save
        width: 160px;
        height: 44px;
        background: #00CCCC;
        border-radius: 6px;
        text-align: center;
        line-height: 44px;
        color: #fff;
        cursor pointer;
        font-size:18px;
        font-weight: bold;
      .sb-cancel
        width: 160px;
        height: 44px;
        border: 1px solid #00CCCC;
        border-radius: 6px;
        text-align: center;
        line-height: 44px;
        color: #00CCCC;
        cursor pointer;
        font-size:18px;
        font-weight: bold;
        margin-left: 30px;

</style>
