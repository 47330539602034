import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Common from '@/views/Common.vue'

Vue.use(VueRouter)

const routes = [
  // component
  {
    path: '/',
    name: 'common',
    redirect: '/home',
    component: Common,
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/home.vue'),
        meta: { name: "1-1", keepAlive: true },
      },

      // 受检管理-受检人列表
      {
        path: '/userList',
        name: 'UserList',
        component: () => import('@/views/user/userList.vue'),
        meta: { name: "2-1", keepAlive: true },
      },

      //用户档案列表
      {
        path: '/userList/fileList',
        component: () => import('@/views/user/fileList.vue')
      },
      //受检管理-受检人档案列表-报告详情页面
      {
        path: '/userList/fileList/report',
        component: () => import('@/views/user/userReportDetail.vue')
      },

      //受检评估
      {
        path: '/spineEvaluation',
        redirect: '/spineEvaluation/chaffer',
        name: 'SpineEvaluation',
        component: () => import('@/views/userManage/spineEvaluation.vue'),
        meta: { name: "2-2", keepAlive: true },
        children: [
          //脊柱初筛
          {
            path: '/spineEvaluation/chaffer',
            name: 'Chaffer',
            component: () => import('@/views/userManage/chaffer.vue'),
            meta: { keepAlive: true },
          },
          //专业脊柱评估
          {
            path: '/spineEvaluation/major',
            name: 'Major',
            component: () => import('@/views/userManage/major.vue'),
            meta: { keepAlive: true },
          },
          //X光检测
          {
            path: '/spineEvaluation/cobb',
            name: 'Cobb',
            component: () => import('@/views/userManage/cobb.vue'),
            meta: { keepAlive: true },
          },
        ]
      },
      //脊柱初筛评估详情
      {
        path: '/chafferDetail',
        name: 'ChafferDetail',
        component: () => import('@/views/userManage/chafferDetail.vue')
      },
      //专业脊柱评估详情
      {
        path: '/majorDetails',
        name: 'MajorDetails',
        component: () => import('@/views/userManage/majorDetails.vue')
      },
      //cobb评估详情
      {
        path: '/cobbDetail',
        name: 'CobbDetail',
        component: () => import('@/views/userManage/cobbDetail.vue')
      },

      // 受检管理-检测任务
      {
        path: '/checkTask',
        name: 'CheckTask',
        component: () => import('@/views/user/checkTask.vue'),
        meta: { name: "2-3", keepAlive: true },
      },
      //受检管理-检测任务-创建任务
      {
        path: '/checkTask/createTask',
        component: () => import('@/views/user/createTask.vue'),
      },
      //受检管理-检测任务-任务详情
      {
        path: '/checkTask/taskDetails',
        component: () => import('@/views/user/taskDetails.vue')
      },
      //受检管理-检测任务-任务详情-受检详情
      {
        path: '/checkTask/taskDetails/byCheckDetail',
        component: () => import('@/views/user/byCheckDetail.vue'),

      },
      //受检管理-检测任务-任务详情-受检详情-单条任务详情  sigleTaskDetail.vue
      {
        path: '/checkTask/taskDetails/sigleTaskList',
        name: 'SigleTaskList',
        component: () => import('@/views/user/sigleTaskList.vue'),
        meta: {
          keepAlive: true,
        },
      },

      //受检管理-检测任务-任务详情-受检详情-单条任务详情--单条任务明细  sigleTaskDetail.vue
      {
        path: '/checkTask/taskDetails/sigleTaskList/detail',
        name: 'SigleDetail',
        component: () => import('@/views/user/sigleTaskDetail.vue'),
        meta: {
          keepAlive: true,
        },
      },

      //系统管理

      //账号管理--wx
      {
        path: '/wxUser',
        name: 'WxUser',
        component: () => import('@/views/controller/wxUser.vue'),
        meta: { name: "3-1", keepAlive: true },
      },
      //账号管理--web
      {
        path: '/webUser',
        name: 'WebUser',
        component: () => import('@/views/controller/webUser.vue'),
        meta: { name: "3-2", keepAlive: true },
      },
      //创建账号
      {
        path: '/createAccount',
        component: () => import('@/views/controller/createAccount.vue')
      },
      //账号明细--wx
      {
        path: '/wxUserDetail',
        component: () => import('@/views/controller/wxUserDetail.vue')
      },
      //账号明细--web
      {
        path: '/webUserDetail',
        component: () => import('@/views/controller/webUserDetail.vue')
      },
      //菜单管理
      {
        path: '/menu',
        component: () => import('@/views/roles/menu.vue'),
        meta: { name: "3-3", keepAlive: true },
      },
      //角色管理
      {
        path: '/role',
        component: () => import('@/views/roles/role.vue'),
        meta: { name: "3-4", keepAlive: true },
      },

      //机构管理
      {
        path: '/orgController',
        name: 'OrgController',
        component: () => import('@/views/controller/orgController.vue'),
        meta: { name: "3-5", keepAlive: true },
      },
      //机构详情
      {
        path: '/orgDetail',
        component: () => import('@/views/controller/orgDetail.vue')
      },
      //创建账号
      {
        path: '/createAccount',
        component: () => import('@/views/controller/createAccount.vue')
      },

      //激活码批次管理
      {
        path: '/batch',
        name: 'Batch',
        component: () => import('@/views/controller/batch.vue'),
        meta: { name: "3-5", keepAlive: true },
      },
      {
        path: '/activationCode',
        component: () => import('@/views/controller/activationCode.vue'),
      },

      //花名册
      //学校列表
      {
        path: '/roster',
        name: 'Roster',
        meta: { keepAlive: true },
        component: () => import('@/views/school/roster.vue'),
        meta: { name: "4-1", keepAlive: true },
      },
      //上传花名册
      {
        path: '/uploadRoster',
        component: () => import('@/views/school/uploadRoster.vue'),
        meta: { name: "4-2", keepAlive: true },
      },

      //年级列表
      {
        path: '/roster/gradeList',
        component: () => import('@/views/school/gradeList.vue')
      },
      //班级列表
      {
        path: '/roster/gradeList/classList',
        component: () => import('@/views/school/classList.vue')
      },
      //学生列表
      {
        path: '/roster/gradeList/classList/studenList',
        component: () => import('@/views/school/studentList.vue')
      },
      //文件管理
      //批量上传
      {
        path: '/uploadfiles',
        component: () => import('@/views/profiles/uploadfiles.vue'),
        meta: { name: "5-1", keepAlive: true },
      },
      //文件处理列表
      {
        path: '/processFileList',
        name: 'ProcessFileList',
        meta: { name: "5-2", keepAlive: true },
        component: () => import('@/views/profiles/processReportList.vue')
      },


      //预约管理
      //预约康复
      {
        path: '/recover',
        name: 'Recover',
        meta: { name: "6-1", keepAlive: true },
        component: () => import('@/views/order/recover.vue')
      },
      //预约康复--详情
      {
        path: '/recover/details',
        name: 'recoverDetails',
        meta: { activeMenu: '/recover' },
        component: () => import('@/views/order/recoverDetails.vue')
      },
      //预约复查 
      {
        path: '/review',
        name: 'Review',
        meta: { name: "6-2", keepAlive: true },
        component: () => import('@/views/order/review.vue')
      },
      //预约复查--详情
      {
        path: '/review/detail',
        name: 'ReviewDetail',
        meta: { activeMenu: '/review' },
        component: () => import('@/views/order/reviewDetail.vue')
      },


      //排班规则
      //排班规则
      {
        path: '/scheduRules',
        name: 'ScheduRules',
        meta: { name: "7-1", keepAlive: true },
        component: () => import('@/views/scheduling/scheduRules.vue')
      },
      //查看规则
      {
        path: '/viewRule',
        name: 'ViewRule',
        meta: { keepAlive: true },
        component: () => import('@/views/scheduling/viewRule.vue')
      },
      //排班情况
      {
        path: '/scheduCondition',
        name: 'ScheduCondition',
        meta: { name: "7-2", keepAlive: true },
        component: () => import('@/views/scheduling/scheduCondition.vue'),
      },
      //新增规则
      {
        path: '/addRules',
        name: 'AddRules',
        meta: { keepAlive: true },
        component: () => import('@/views/scheduling/addRule.vue')
      },

      //机构信息
      //基本信息
      {
        path: '/baseInfo',
        name: 'BaseInfo',
        meta: { name: "8-1", keepAlive: true },
        component: () => import('@/views/orgInfo/baseinfo.vue')
      },

      //科室管理
      {
        path: '/department',
        name: 'Department',
        meta: { name: "8-2", keepAlive: true },
        component: () => import('@/views/orgInfo/department.vue')
      },
      //添加科室
      {
        path: '/addDepart',
        meta: { keepAlive: true },
        component: () => import('@/views/orgInfo/addDepart.vue')
      },
      //编辑科室
      {
        path: '/editDepartment',
        meta: { keepAlive: true },
        component: () => import('@/views/orgInfo/editDepartment.vue')
      },
      //专家管理
      //专家列表
      {
        path: '/expertList',
        name: 'ExpertList',
        meta: { name: "9-1", keepAlive: true },
        component: () => import('@/views/expert/expertList.vue')
      },
      //查看专家
      {
        path: '/viewExpert',
        name: 'ViewExpert',
        meta: { keepAlive: true },
        component: () => import('@/views/expert/viewExpert.vue')
      },
      //申请列表
      {
        path: '/applyList',
        name: 'ApplyList',
        meta: { name: "9-2", keepAlive: true },
        component: () => import('@/views/expert/applyList.vue')
      },
      //查看申请列表
      {
        path: '/viewApply',
        name: 'ViewApply',
        meta: { keepAlive: true },
        component: () => import('@/views/expert/viewApply.vue')
      },
    ]
  },
  //login 
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  //重置密码
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/views/login/resetPassword.vue')
  },
  //下载团体报告
  {
    path: '/downloadReport',
    component: () => import('@/components/other/downloadReport.vue')
  },

]

const router = new VueRouter({
  routes,
  mode: 'hash',
})


//token 登录路由拦截
router.beforeEach((to, from, next) => {
  //判断跳转的路径是否是登录页面，若是，则不拦截，直接跳转；
  //若不是，则验证Authorization是否为空
  //若为空，则说明未登录，跳转到登录页面。若不为空，说明已登录，直接跳转到相应页面
  if (to.path === '/login') {

    next();
  } else {
    let token = localStorage.getItem('token');
    if (token === null || token === '') {
      next('/login');
    } else {
      store.commit('clearCancelToken')
      next();
    }
  }
});

export default router
