import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  cancelTokenArr: []
}

const mutations = {
  addCancelToken(state, cancel) {
    state.cancelTokenArr.push(cancel)
  },
  clearCancelToken(state) {
    state.cancelTokenArr.forEach(c => {
      c()
    })
    state.cancelTokenArr = []
  }
}

const store = new Vuex.Store({
  state,
  mutations
})

export default store