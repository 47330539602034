import Vue from 'vue'
import App from './App.vue'
import * as serviceWorker from './registerServiceWorker'
import router from './router'
import store from './store'
import 'amfe-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/fonts/iconfont.css'
import * as echarts from 'echarts'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts
import Directives from '@/utils/directive.js'
Vue.use(Directives)

//配置v-viewer
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
      "inline": true,  //启用 inline 模式 默认false
      "button": true,//显示右上角关闭按钮 默认true
      "navbar": true,//显示缩略图导航 默认true
      "title": true,//显示当前图片的标题 默认true
      "toolbar": true,// 显示工具栏 默认true
      "tooltip": true,// 显示缩放百分比 默认true
      "movable": true,//图片是否可移动 默认true
      "zoomable": true,//图片是否可缩放 默认true
      "rotatable": true,//图片是否可旋转 默认true
      "scalable": true,//图片是否可翻转 默认true
      "transition": true,//使用css3过度 默认true
      "fullscreen": true,//是否全屏 默认true
      "keyboard": true,//是否支持键盘 默认true
      "url": "data-source" //设置大图片的URL
  }
});

// add loading
Vue.prototype.openLoading = function() {
  const loading = this.$loading({           // 声明一个loading对象
    lock: true,                             // 是否锁屏
    text: '正在加载...',                     // 加载动画的文字
    spinner: 'el-icon-loading',             // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.3)',       // 背景颜色
    target: '.sub-main',                    // 需要遮罩的区域
    body: true,                              
    customClass: 'mask'                     // 遮罩层新增类名
  })
  setTimeout(function () {                  // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close();                        // 关闭遮罩层
  },5000)
  return loading;
}

Date.prototype.Format = function(a) {
  const b = {
    "M+": this.getMonth() + 1,
    "d+": this.getDate(),
    "h+": this.getHours(),
    "m+": this.getMinutes(),
    "s+": this.getSeconds(),
    "q+": Math.floor((this.getMonth() + 3) / 3),
    S: this.getMilliseconds()
  };
  /(y+)/.test(a) &&
    (a = a.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    ));
  for (const c in b) {
    RegExp("(" + c + ")").test(a) &&
      (a = a.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? b[c] : ("00" + b[c]).substr(("" + b[c]).length)
      ));
  }
  return a;
};

//不重复添加元素属性
Array.prototype.pushNoRepeat = function(){
  for(var i=0; i<arguments.length; i++){
    var ele = arguments[i];
    if(this.indexOf(ele) == -1){
        this.push(ele);
    }
}
};

serviceWorker.unregister();//卸载serviceWorker





new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
